<template>
	<v-app>
		<v-main>
			<v-row class="fill-height" align="start" justify="center">
				<!-- Left Side Image Section -->
				<v-col cols="12" md="6" class="d-none d-md-block pa-0">
					<v-img
						src="@/assets/img/common/login.jpg"
						alt="sign up page illustration"
					>
					</v-img>
				</v-col>

				<!-- Right Side Form Section -->
				<v-col cols="12" md="6" class="mt-8 px-6 px-sm-16">
					<div class="mx-0 mx-sm-8 mx-md-n6 mx-lg-16">
						<!-- Header Content -->
						<header>
							<a href="https://www.unosearch.net">
								<v-img
									src="@/assets/img/logo/brand-icon.svg"
									max-height="60"
									max-width="60"
									class="mb-6"
									to="/"
									contain
								></v-img>
							</a>

							<h1>Login</h1>
							<p class="grey--text text--darken-1">
								Welcome back! Login to access UnoSearch
							</p>
						</header>

						<!-- Actual Form -->
						<v-form class="mt-10" @submit.prevent="login">
							<!-- Alert box to display Server Errors -->
							<v-alert
								:value="showServerError"
								type="error"
								dismissible
								transition="fade-transition"
								@click="showServerError = !showServerError"
								class="mb-8"
							>
								{{ serverErrorMessage }}
							</v-alert>

							<!-- Email Field -->
							<v-text-field
								v-model="loginForm.email"
								placeholder="Email address"
								required
								outlined
								type="email"
								:error-messages="emailErrors"
								@blur="$v.loginForm.email.$touch()"
							></v-text-field>

							<!-- Password Field -->
							<v-text-field
								v-model="loginForm.password"
								outlined
								placeholder="Password"
								required
								:append-icon="
									showPassword
										? 'mdi mdi-eye-off'
										: 'mdi mdi-eye'
								"
								@click:append="showPassword = !showPassword"
								:type="showPassword ? 'text' : 'password'"
								:error-messages="passwordErrors"
								@blur="$v.loginForm.password.$touch()"
							></v-text-field>

							<!-- Remember Me Checkbox AND Forgot Password Link -->
							<div class="d-flex flex-row justify-space-between">
								<v-checkbox
									v-model="loginForm.rememberMe"
									label="Remember me"
									color="primary"
									value="primary"
									class="ma-0 pa-0"
								></v-checkbox>
								<router-link
									to="/forgot-password"
									class="link-text"
								>
									Forgot your password?
								</router-link>
							</div>

							<!-- Submit Button -->
							<v-btn
								type="submit"
								@click.prevent="login"
								:loading="isLoading"
								x-large
								block
								color="primary"
								class="text-capitalize white--text mt-2"
							>
								Login
							</v-btn>

							<!-- Link to Sign Up Page -->
							<p
								class="
									text-center
									mt-8
									grey--text
									text--darken-1
								"
							>
								Not registered yet?
								<router-link to="/sign-up" class="link-text">
									Create an Account
								</router-link>
							</p>
						</v-form>
					</div>
				</v-col>
			</v-row>
		</v-main>
	</v-app>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'

export default {
	mixins: [validationMixin],
	validations: {
		loginForm: {
			email: {
				required,
				email,
				maxLength: maxLength(100),
			},
			password: {
				required,
				maxLength: maxLength(100),
			},
		},
	},
	data() {
		return {
			loginForm: {
				email: '',
				password: '',
				rememberMe: false,
			},
			showPassword: false,
			showServerError: false,
			serverErrorMessage: '',
			isLoading: false,
		}
	},
	computed: {
		emailErrors() {
			const errors = []
			if (!this.$v.loginForm.email.$dirty) return errors
			!this.$v.loginForm.email.required &&
				errors.push('Please enter your Email address.')
			!this.$v.loginForm.email.email &&
				errors.push('Email must be valid.')
			!this.$v.loginForm.email.maxLength &&
				errors.push('Email must be atmost 100 characters long.')
			return errors
		},
		passwordErrors() {
			const errors = []
			if (!this.$v.loginForm.password.$dirty) return errors
			!this.$v.loginForm.password.required &&
				errors.push('Please enter your Password.')
			!this.$v.loginForm.password.maxLength &&
				errors.push('Password must be atmost 100 characters long.')
			return errors
		},
	},
	methods: {
		login() {
			this.$v.loginForm.$touch()
			if (!this.$v.loginForm.$invalid || !this.$v.loginForm.$error) {
				this.isLoading = true
				this.axios
					.post('/auth/login', this.loginForm)
					.then((res) => res.data)
					.catch((err) => {
						const { data } = err.response
						this.isLoading = false
						this.loginForm.password = ''
						this.$v.loginForm.$reset()
						this.serverErrorMessage = !data.success
							? data.message
							: err
						this.showServerError = true
						throw new Error(err)
					})
					.then(async (res) => {
						if (res.success) {
							await this.$cookies.set('x-auth-token', res.token)
							this.isLoading = false
							if (
								this.$route.query &&
								this.$route.query.planName &&
								this.$route.query.planPeriod
							) {
								this.$router.push({
									path: '/projects',
									query: {
										planName: this.$route.query.planName,
										planPeriod:
											this.$route.query.planPeriod,
									},
								})
							} else {
								this.$router.push('/projects')
							}
						}
					})
					.catch((err) => {
						this.isLoading = false
						throw new Error(err)
					})
			}
		},
	},
}
</script>

<style scoped>
.v-image {
	height: 100vh !important;
}
.v-input {
	font-size: 1.1rem;
}
.link-text {
	text-decoration: none;
	color: --var(primary);
}
</style>
